h1 {
    font-size: calc(36px * var(--head-fontSize, 1)) !important;
    line-height: calc(54px * var(--head-fontHeight, 1)) !important;
    font-weight: var(--head-fontWeight, 600) !important;
}

h3 {
    font-size: calc(32px * var(--head-fontSize, 1)) !important;
    line-height: calc(50px * var(--head-fontHeight, 1)) !important;
    font-weight: var(--head-fontWeight, 600) !important;
}

p {
    font-size: calc(24px * var(--head-fontSize, 1)) !important;
    line-height: calc(32px * var(--head-fontHeight, 1)) !important;
}

.testimony {
    padding-left: 200px;
    padding-right: 200px;
    padding-top: 100px;
    padding-bottom: 200px;
}

.third-section-,
.first-section-p {
    text-align: justify;
    font-size: calc(30px * var(--head-fontSize, 1)) !important;
}

.app-container {
    padding-left: 130px;
    padding-right: 130px;
}


@media (min-width: 768px) {
    h1 {
        font-size: calc(60px * var(--head-fontSize, 1)) !important;
        line-height: calc(54px * var(--head-fontHeight, 1)) !important;
    }

    .third-section-p {
        max-width: "100%";
    }
}

@media (min-width: 1450px) {
    .app-container {
        padding-left: 90px;
        padding-right: 90px;
    }
}

@media (max-width: 1400px) {
    .app-container {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (max-width: 1000px) {
    .testimony {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 60px;
        padding-bottom: 80px;
    }
}

@media (max-width: 768px) {
    .app-container {
        padding-left: 0px;
        padding-right: 0px;
    }

    .testimony {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 10px;
        padding-bottom: 30px;
    }
}

